import { CapacitorHttp } from '@capacitor/core'
import axios, { AxiosProgressEvent, AxiosRequestConfig, AxiosResponseHeaders } from 'axios'
import qs from 'qs'
import { MOBILE_MODE, SERVER_API_URL } from '@/env'
import { getAuthToken } from '@/config/axios-interceptor'

function constructUrl(baseUrl: string, endpoint: string): string {
   if (/^https?:\/\//.test(endpoint)) return endpoint

   return `${baseUrl.replace(/\/+$/, '')}/${endpoint.replace(/^\/+/, '')}`
}

function addAuthToken(headers: Record<string, string> = {}): Record<string, string> {
   const token = getAuthToken()
   if (token) {
      return { ...headers, Authorization: token }
   }
   return headers
}

export class RestClient {
   /**
    * Perform a GET request.
    */
   static async get<T>(
      url: string,
      config?: { params?: Record<string, any>; headers?: Record<string, string> },
   ): Promise<{ data: T; status: number; headers: Record<string, string> }> {
      const { params, headers } = config || {}

      // Serialize params and append to URL
      const queryString = params ? `?${qs.stringify(params, { arrayFormat: 'repeat' })}` : ''
      const fullUrl = constructUrl(SERVER_API_URL, url) + queryString

      if (MOBILE_MODE) {
         const response = await CapacitorHttp.get({
            url: fullUrl,
            headers: addAuthToken(headers),
         })
         return {
            data: response.data as T,
            status: response.status || 200,
            headers: this.normalizeHeaders(response.headers || {}),
         }
      }

      if (axios) {
         const axiosConfig: AxiosRequestConfig = { headers }
         const response = await axios.get<T>(fullUrl, axiosConfig)
         return {
            data: response.data,
            status: response.status,
            headers: this.normalizeHeaders(response.headers),
         }
      }

      const mergedConfig: RequestInit = {
         method: 'GET',
         headers,
      }

      const response = await fetch(fullUrl, mergedConfig)
      await RestClient.checkStatus(response)
      const data = await response.json()
      return {
         data: data as T,
         status: response.status,
         headers: RestClient.parseHeaders(response.headers),
      }
   }

   /**
    * Perform a POST request.
    */
   static async post<T>(
      url: string,
      data?: unknown,
      config?: RequestInit & {
         params?: Record<string, any>
         onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
      },
   ): Promise<{ data: T; status: number; headers: Record<string, string> }> {
      const { params, onUploadProgress, headers, ...restConfig } = config || {}

      // Serialize params and append to URL
      const queryString = params ? `?${qs.stringify(params, { arrayFormat: 'repeat' })}` : ''
      const fullUrl = constructUrl(SERVER_API_URL, url) + queryString

      if (MOBILE_MODE) {
         let options = {
            url: fullUrl,
            headers: addAuthToken({
               'Content-Type': RestClient.getContentType(data),
               ...(headers as Record<string, string>),
            }),
            data: data instanceof FormData ? data : JSON.stringify(data),
         }
         console.log('Before post request ', JSON.stringify(options))
         const response = await CapacitorHttp.post(options)
         return {
            data: response.data as T,
            status: response.status || 200,
            headers: this.normalizeHeaders(response.headers || {}),
         }
      }

      if (axios) {
         const axiosConfig: AxiosRequestConfig = {
            headers: headers as Record<string, string>,
            onUploadProgress, // Pass the onUploadProgress callback
            params, // Axios supports params natively
         }
         const response = await axios.post<T>(fullUrl, data, axiosConfig)
         return {
            data: response.data,
            status: response.status,
            headers: this.normalizeHeaders(response.headers),
         }
      }

      const mergedConfig: RequestInit = {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
            ...(headers || {}),
         },
         body: data instanceof FormData ? data : data !== undefined ? JSON.stringify(data) : undefined,
         ...restConfig, // Include any remaining properties from config
      }

      // Fetch does not support params natively, so we append them to the URL
      const response = await fetch(fullUrl, mergedConfig)
      await RestClient.checkStatus(response)
      const responseData = await response.json()
      return {
         data: responseData as T,
         status: response.status,
         headers: RestClient.parseHeaders(response.headers),
      }
   }

   /**
    * Perform a PUT request.
    */
   static async put<T>(
      url: string,
      data?: unknown,
      config?: RequestInit,
   ): Promise<{ data: T; status: number; headers: Record<string, string> }> {
      const fullUrl = constructUrl(SERVER_API_URL, url)

      if (MOBILE_MODE) {
         const response = await CapacitorHttp.put({
            url: fullUrl,
            headers: addAuthToken(config?.headers as Record<string, string>),
            data,
         })
         return {
            data: response.data as T,
            status: response.status || 200,
            headers: this.normalizeHeaders(response.headers || {}),
         }
      }

      if (axios) {
         const axiosConfig: AxiosRequestConfig = { headers: config?.headers as Record<string, string> }
         const response = await axios.put<T>(fullUrl, data, axiosConfig)
         return {
            data: response.data,
            status: response.status,
            headers: this.normalizeHeaders(response.headers),
         }
      }

      const mergedConfig: RequestInit = {
         method: 'PUT',
         headers: {
            'Content-Type': 'application/json',
            ...(config?.headers || {}),
         },
         body: data !== undefined ? JSON.stringify(data) : undefined,
         ...config,
      }

      const response = await fetch(fullUrl, mergedConfig)
      await RestClient.checkStatus(response)
      const responseData = await response.json()
      return {
         data: responseData as T,
         status: response.status,
         headers: RestClient.parseHeaders(response.headers),
      }
   }

   /**
    * Perform a DELETE request.
    */
   static async delete<T>(
      url: string,
      config?: RequestInit,
   ): Promise<{ data: T; status: number; headers: Record<string, string> }> {
      const fullUrl = constructUrl(SERVER_API_URL, url)

      if (MOBILE_MODE) {
         const response = await CapacitorHttp.delete({
            url: fullUrl,
            headers: config?.headers as Record<string, string>,
         })
         return {
            data: response.data as T,
            status: response.status || 200,
            headers: this.normalizeHeaders(response.headers || {}),
         }
      }

      if (axios) {
         const axiosConfig: AxiosRequestConfig = { headers: config?.headers as Record<string, string> }
         const response = await axios.delete<T>(fullUrl, axiosConfig)
         return {
            data: response.data,
            status: response.status,
            headers: this.normalizeHeaders(response.headers),
         }
      }

      const mergedConfig: RequestInit = {
         method: 'DELETE',
         ...config,
      }

      const response = await fetch(fullUrl, mergedConfig)
      await RestClient.checkStatus(response)
      const responseData = await response.json()
      return {
         data: responseData as T,
         status: response.status,
         headers: RestClient.parseHeaders(response.headers),
      }
   }

   /**
    * (Optional) Helper to check HTTP status and throw on error.
    */
   private static async checkStatus(response: Response): Promise<void> {
      if (!response.ok) {
         const errorText = await response.text()
         throw new Error(`HTTP error ${response.status}: ${errorText}`)
      }
   }

   /**
    * Parse headers from Fetch API response.
    */
   private static parseHeaders(headers: Headers): Record<string, string> {
      const parsedHeaders: Record<string, string> = {}
      headers.forEach((value, key) => {
         parsedHeaders[key] = value
      })
      return parsedHeaders
   }

   /**
    * Normalize headers from Axios or other sources to Record<string, string>.
    */
   private static normalizeHeaders(headers: AxiosResponseHeaders | Record<string, any>): Record<string, string> {
      if (headers && typeof headers === 'object' && !(headers instanceof Headers)) {
         const normalized: Record<string, string> = {}

         // Use a for-in loop to iterate over headers and convert keys to lowercase
         for (const key in headers) {
            if (Object.prototype.hasOwnProperty.call(headers, key) && key.toLowerCase() !== 'content-length') {
               normalized[key.toLowerCase()] = String(headers[key]) // Normalize key to lowercase
            }
         }

         return normalized
      }

      // Ensure compatibility when `headers` is already a Record<string, string>
      return headers as Record<string, string>
   }

   private static getContentType(data: unknown): string {
      if (data instanceof FormData) return 'multipart/form-data'
      if (typeof data === 'object') return 'application/json'
      return 'text/plain'
   }
}
