import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SERVER_API_URL } from '@/env'
import { toast } from 'react-toastify'
import { showedAllNotifications } from '@/service/notification/notification-service'
import { debounce } from 'lodash'
import { getShoppingCard } from '@/service/shopping-card.service'

let source: EventSource
let reconnectTimer: number
let hasExecuted = false

let backoffDelay = 4000 //4s
const maxBackoffDelay = 15000

export const setupNotifications = (t, dispatch, isAuthenticated: boolean) => {
   if (!isAuthenticated) return

   let notificationCount = 0
   let messageCount = 0

   const newNotificationsIds = Array<string>()
   const newMessagesIds = Array<string>()

   const showNotifications = debounce(() => {
      if (notificationCount > 0) {
         const toastMessage =
            notificationCount === 1
               ? t('notifications.new_notification', { type: 'info', duration: 1200 })
               : t('notifications.new_notifications', { type: 'info', duration: 1800 })
         toast.success(toastMessage)
         dispatch(addUnreadNotifications(newNotificationsIds))
      }

      if (messageCount > 0) {
         const toastMessage =
            messageCount === 1
               ? t('notifications.new_message', { type: 'info', duration: 1500 })
               : t('notifications.new_messages', { type: 'info', duration: 2000 })
         toast.success(toastMessage)
         dispatch(addUnreadMessages(newMessagesIds))
      }

      // Reset counts
      notificationCount = 0
      messageCount = 0
   }, 1000) // 1 second

   if (!hasExecuted) {
      if (!isAuthenticated) {
         hasExecuted = true
      } else {
         showedAllNotifications()
            .then(response => {
               dispatch(addUnreadNotifications(response.data?.newNotifications ?? []))
               dispatch(addUnreadMessages(response.data?.newMessages ?? []))
            })
            .finally(() => {
               hasExecuted = true
            })
         getShoppingCard().then(response => {
            const data = response.data
            const foodsIds = data.flatMap(chefObj => {
               return chefObj.orders.flatMap(order => {
                  return Array(order.quantity).fill(order.food.id)
               })
            })
            dispatch(updateItemsInSC(foodsIds))
         })
      }
   }

   source = new EventSource(
      `${SERVER_API_URL}api/notifications/new?access_token=` +
         (localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken')),
   )

   source.addEventListener('*', () => {})

   source.onmessage = function (data) {
      const event = JSON.parse(data.data)
      switch (event.notificationType) {
         case 'HAS_NEW_NOTIFICATION':
            notificationCount += 1
            newNotificationsIds.push(event.id)
            break
         case 'HAS_NEW_MESSAGE':
            messageCount += 1
            newMessagesIds.push(event.id)
            break
      }
      // Call debounced function
      showNotifications()
   }

   source.onerror = function (event) {
      source.close()
      if (source.readyState === EventSource.CLOSED && isAuthenticated) {
         // @ts-ignore
         reconnectTimer = setTimeout(() => {
            setupNotifications(t, dispatch, isAuthenticated)
            backoffDelay = Math.min(backoffDelay * 2, maxBackoffDelay)
         }, backoffDelay)
      }
   }
   return source
}

const INITIAL_STATE = {
   newNotifications: Array<string>(),
   newMessages: Array<string>(),

   newOrders: 0,
   itemsIdsInShoppingCard: Array<string>(),
}

export const NotificationsSlice = createSlice({
   name: 'notifications',
   initialState: INITIAL_STATE,
   reducers: {
      addUnreadNotifications(state, action: PayloadAction<string[]>) {
         action.payload.forEach(id => {
            if (!state.newNotifications.includes(id)) {
               state.newNotifications.push(id)
            }
         })
      },

      clearNewNotifications(state) {
         state.newNotifications = []
      },

      addUnreadMessages(state, action: PayloadAction<string[]>) {
         action.payload.forEach(id => {
            if (!state.newMessages.includes(id)) {
               state.newMessages.push(id)
            }
         })
      },

      clearNewMessages(state) {
         state.newMessages = []
      },

      updateItemsInSC(state, action: PayloadAction<string[]>) {
         state.itemsIdsInShoppingCard = action.payload
      },
      removeItemIdFromSC(state, action: PayloadAction<string[]>) {
         action.payload.forEach(itemId => {
            const index = state.itemsIdsInShoppingCard.indexOf(itemId)
            if (index !== -1) {
               // Remove only the first occurrence
               state.itemsIdsInShoppingCard.splice(index, 1)
            }
         })
      },
      removeItemsIdsFromSC(state, action: PayloadAction<string[]>) {
         state.itemsIdsInShoppingCard = state.itemsIdsInShoppingCard.filter(itemId => !action.payload.includes(itemId))
      },
      addItemsIdsToSC(state, action: PayloadAction<string[]>) {
         action.payload.forEach(itemId => {
            state.itemsIdsInShoppingCard.push(itemId)
         })
      },
   },
})

export const {
   addUnreadNotifications,
   clearNewNotifications,
   addUnreadMessages,
   clearNewMessages,
   updateItemsInSC,
   addItemsIdsToSC,
   removeItemsIdsFromSC,
   removeItemIdFromSC,
} = NotificationsSlice.actions

export default NotificationsSlice.reducer
