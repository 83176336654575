
    import {PRODUCTION} from './src/env'

    if (PRODUCTION) {
        console.log('register PWA')
        import('virtual:pwa-register')
            .then(({registerSW}) => {
                const updateSW = registerSW({
                    immediate: true,
                    onNeedRefresh() {
                        console.log(`New Content is available; please refresh. [${new Date().getHours()}:${new Date().getMinutes()}]`)
                        updateSW(false)
                    },
                    onOfflineReady() {
                        console.log('App is ready to work offline')
                        // Optional: Notify user that the app is ready to work offline
                    },
                })
            })
            .catch((error) => {
                console.error('Failed to register PWA:', error)
            })
    } else {
        console.log('PWA not registered')
    }
