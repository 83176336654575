import { RestClient } from '@/config/rest-client'
import { FoodOrder, OrdersForChef } from '@/model/order/Food-order'
import { FoodPaymentRequest } from '@/model/order/food-payment.request'
import { OrderPayment } from '@/model/order/orderPayment'

const apiUrl = '/api/shopping-card'

export const handleFoodOrder = (foodOrder: FoodOrder) => {
   return RestClient.post<void>(apiUrl + '/handle-order', foodOrder)
}

export const getShoppingCard = () => {
   return RestClient.get<OrdersForChef[]>(apiUrl + '/get-SC')
}

export const getShoppingCardOrderByChef = (chefId: string) => {
   return RestClient.get<OrdersForChef>(apiUrl + `/get-SC/${chefId}`)
}

export const getItemsInShoppingCard = () => {
   return RestClient.get<Array<string>>(apiUrl + '/get-compact-orders-in-sc')
}

export const createPaymentIntent = (request: FoodPaymentRequest) => {
   return RestClient.post<OrderPayment>(apiUrl + '/create-payment-intent', request)
}

export const getPaymentById = (id: string) => {
   return RestClient.get<OrderPayment>(apiUrl + '/payment/' + id)
}

export const confirmPayment = ({ paymentIntentId, stripePaymentId }) => {
   return RestClient.post<OrderPayment>(`${apiUrl}/payment/${paymentIntentId}/${stripePaymentId}/confirm`)
}

export const logStripeError = (error: string) => {
   return RestClient.post<void>(apiUrl + '/log-stripe-error', error)
}

export const deleteFoodOrder = (foodOrderId: string) => {
   return RestClient.delete<void>(apiUrl + '/delete-order/' + foodOrderId)
}

export interface DashboardLink {
   url: string
   linkType: string
}

export const getMyDashboard = () => {
   return RestClient.get<DashboardLink>(apiUrl + '/my-dashboard')
}
export const getMyClientSecret = () => {
   return RestClient.get<string>(apiUrl + '/get-client-secret')
}

export interface CapabilitiesDTO {
   capabilities: string[]
   onboardingURL: string
}

export const getMyCapabilities = (complete = false) => {
   return RestClient.get<CapabilitiesDTO>(apiUrl + '/get-my-capabilities/' + complete)
}

export const refreshConnectAccountAfterUpdate = () => {
   return RestClient.post<void>(apiUrl + '/refresh-connect-account')
}
