import { RestClient } from '@/config/rest-client'
import { createSlice } from '@reduxjs/toolkit'
import { LoginFormData } from '@/model/login.model'
import { RegisterFormGoogleData } from '@/model/register.model'
import { AppThunk } from '@/config/store'
import { getAccount } from '@/service/user-settings-reducer'
import { IUser } from '@/model/user.model'
import { UserInfoFormData } from '@/layout/add-chef/CreateChefPageZeroStep'

const AUTH_TOKEN_KEY = 'authenticationToken'
const AUTH_INFO = 'authenticationInfo'

const getInitialAuthState = () => {
   const localAuth = localStorage.getItem(AUTH_INFO)
   const sessionAuth = sessionStorage.getItem(AUTH_INFO)
   const authData = localAuth ? JSON.parse(localAuth) : sessionAuth ? JSON.parse(sessionAuth) : null

   return {
      account: authData || ({} as IUser),
      isAuthenticated: !!authData,
      sessionHasBeenFetched: !!authData,
   }
}

export const initialState = {
   loading: false,
   isAuthenticated: getInitialAuthState().isAuthenticated,
   loginSuccess: false,
   loginError: false, // Errors returned from server side
   showModalLogin: false,
   showModalRegister: false,
   showForgotPassword: false,
   account: getInitialAuthState().account,
   errorMessage: null as unknown as string, // Errors returned from server side
   redirectMessage: null as unknown as string,
   sessionHasBeenFetched: getInitialAuthState().sessionHasBeenFetched,
   logoutUrl: null as unknown as string,
   cookiesAccepted: false,
}

export const logoutState = {
   loading: false,
   isAuthenticated: false,
   loginSuccess: false,
   loginError: false, // Errors returned from server side
   showModalLogin: false,
   showModalRegister: false,
   showForgotPassword: false,
   account: {} as IUser,
   errorMessage: null as unknown as string, // Errors returned from server side
   redirectMessage: null as unknown as string,
   sessionHasBeenFetched: false,
   logoutUrl: null as unknown as string,
   cookiesAccepted: false,
}

export type AuthenticationState = Readonly<typeof initialState>

export interface GoogleAuthResponse {
   email: string
   googleUserExists: boolean
   emailExists: boolean
}

export const getSession = async dispatch => {
   const result = await getAccount()
   if (localStorage.getItem(AUTH_TOKEN_KEY)) {
      localStorage.setItem(AUTH_INFO, JSON.stringify(result?.data))
   } else {
      sessionStorage.setItem(AUTH_INFO, JSON.stringify(result?.data))
   }

   dispatch(updateState(result.data))
   return result.data
}

// gives authorization header in token
export const login = async (formData: LoginFormData, dispatch) => {
   const result = await RestClient.post<void>('api/authenticate', formData)
   return saveTokenAndGetUserInfo(result?.headers?.authorization, formData.rememberMe, dispatch)
}

// gives authorization header in token + GoogleAuthResponse
export const googleLoginRegister = async (token: string) => {
   return await RestClient.post<GoogleAuthResponse>('api/google', { token })
}

export const registerNewUser = async (formData: UserInfoFormData) => {
   return RestClient.post<void>('api/register', formData)
}

export const validateUserCredentials = async (formData: UserInfoFormData) => {
   return RestClient.post<string>('api/validate-user', formData)
}

// gives authorization header in token + GoogleAuthResponse
export const registerNewUserWithGoogle = async (formData: RegisterFormGoogleData) => {
   return RestClient.post<void>('api/register-google', formData)
}

export const resetPassword = (email: string) => {
   return RestClient.post<any>('api/account/reset-password/init', { mail: email })
}

export const resendActivationEmail = formData => {
   return RestClient.post<any>('api/account/resend-email', formData)
}

export const resetPasswordFinish = formData => {
   return RestClient.post<any>('api/account/reset-password/finish', formData)
}

export const clearAuthTokenAndUserData = () => {
   if (localStorage.getItem(AUTH_TOKEN_KEY)) localStorage.removeItem(AUTH_TOKEN_KEY)
   if (sessionStorage.getItem(AUTH_TOKEN_KEY)) sessionStorage.removeItem(AUTH_TOKEN_KEY)
   if (localStorage.getItem(AUTH_INFO)) localStorage.removeItem(AUTH_INFO)
   if (sessionStorage.getItem(AUTH_INFO)) sessionStorage.removeItem(AUTH_INFO)
}

export function saveTokenAndGetUserInfo<T>(bearerToken: string, rememberMe: boolean, dispatch) {
   let jwt = ''

   if (bearerToken.startsWith('Bearer ')) jwt = bearerToken.slice(7)
   else jwt = bearerToken

   if (jwt) {
      if (rememberMe) localStorage.setItem(AUTH_TOKEN_KEY, jwt)
      else sessionStorage.setItem(AUTH_TOKEN_KEY, jwt)
   }
   return getSession(dispatch)
}

export const logout: () => AppThunk = () => dispatch => {
   clearAuthTokenAndUserData()
   sessionStorage.clear()
   localStorage.clear()
   dispatch(logoutSession())
}

export const showLoginModal: () => AppThunk = () => dispatch => {
   dispatch(showLogin())
}

export const hideLoginModal: () => AppThunk = () => dispatch => {
   dispatch(hideLogin())
}

export const hideLoginOpenRegisterModal: () => AppThunk = () => dispatch => {
   dispatch(hideLogin())
   dispatch(showRegister())
}

export const showRegisterModal: () => AppThunk = () => dispatch => {
   dispatch(showRegister())
}

export const hideRegisterModal: () => AppThunk = () => dispatch => {
   dispatch(hideRegister())
}
export const showForgotPasswordModal: () => AppThunk = () => dispatch => {
   dispatch(showForgotPassword())
}

export const hideForgotPasswordModal: () => AppThunk = () => dispatch => {
   dispatch(hideForgotPassword())
}

export const clearAuthentication = () => dispatch => {
   clearAuthTokenAndUserData()

   dispatch(authError(''))
   dispatch(logoutSession())

   dispatch(showLogin())
}

export const AuthenticationSlice = createSlice({
   name: 'authentication',
   initialState: initialState as AuthenticationState,
   reducers: {
      logoutSession() {
         return {
            ...logoutState,
         }
      },
      authError(state, action) {
         return {
            ...state,
            redirectMessage: action.payload,
         }
      },
      showLogin(state) {
         return {
            ...state,
            showModalLogin: true,
            errorMessage: null,
         }
      },
      hideLogin(state) {
         return {
            ...state,
            showModalLogin: false,
            errorMessage: null,
         }
      },
      showRegister(state) {
         return {
            ...state,
            showModalRegister: true,
         }
      },
      hideRegister(state) {
         return {
            ...state,
            showModalRegister: false,
            errorMessage: null,
         }
      },
      showForgotPassword(state) {
         return {
            ...state,
            showForgotPassword: true,
         }
      },
      hideForgotPassword(state) {
         return {
            ...state,
            showForgotPassword: false,
            errorMessage: null,
         }
      },
      updateState(state, account) {
         return {
            ...state,
            showModalRegister: false,
            account: account.payload,
            isAuthenticated: true,
            loginSuccess: true,
            showModalLogin: false,
         }
      },
      updateAccountState(state, account) {
         console.log('account updated ', account)
         if (localStorage.getItem(AUTH_TOKEN_KEY)) {
            localStorage.setItem(AUTH_INFO, JSON.stringify(account.payload))
         } else {
            sessionStorage.setItem(AUTH_INFO, JSON.stringify(account.payload))
         }

         return {
            ...state,
            account: account.payload,
         }
      },
      updateCookiesState(state, account) {
         return {
            ...state,
            cookiesAccepted: account.payload,
         }
      },
   },
})

export const {
   logoutSession,
   authError,
   showLogin,
   hideLogin,
   showRegister,
   hideRegister,
   updateState,
   showForgotPassword,
   hideForgotPassword,
   updateAccountState,
   updateCookiesState,
} = AuthenticationSlice.actions

// Reducer
export default AuthenticationSlice.reducer
