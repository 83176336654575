export const PRODUCTION = import.meta.env.VITE_PRODUCTION === 'true'
export const MOBILE_MODE = import.meta.env.VITE_MOBILE_MODE === 'true'

export const CDN_ENDPOINT = import.meta.env.VITE_CDN_ENDPOINT || 'http://localhost:9000/chefs/DEV/'
export const CDN_ENDPOINT_STATIC_CONTENT = `${CDN_ENDPOINT}STATIC_CONTENT/`

export const SERVER_API_URL = import.meta.env.VITE_SERVER_API_URL || 'http://localhost:8081/'
export const SITE_BASE_URL = import.meta.env.VITE_SERVER_API_URL || 'http://localhost:5174/'

export const REACT_APP_STRIPE_PUBLISHABLE_KEY =
   import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
   'pk_test_51Pakn3HMDtENV9DMwWKSB27y8kamSkjZVcxDXI5ImWgxEfI5MbQDpH7mfJmfODs1nZZkWdRtq8c07wtjk5jFo01500Aiuublc9'
