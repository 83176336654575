import { RestClient } from '@/config/rest-client'
import { UserLocation } from '@/util/UserLocalizations'

const apiUrl = '/api/user-locations'

export const saveUserLocation = (userLocation: UserLocation) => {
   return RestClient.post<void>(apiUrl, userLocation)
}

export const getLastUserLocation = () => {
   return RestClient.get<UserLocation | null>(`${apiUrl}/last`)
}
